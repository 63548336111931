<template>
  <div class="tickets-table">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $vuetify.lang.t('$vuetify.different.filter') }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="filters.projects"
                    :items="compProjects"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.different.project')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.different.selectProject')"
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="name"
                    prepend-icon="mdi-briefcase"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="filters.statuses"
                    :items="compTicketStatuses"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.status.status')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.status.selectStatus')"
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="title"
                    prepend-icon="list"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="filters.categories"
                    :items="compTicketCategories"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.category.category')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.category.selectCategory')"
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="title"
                    prepend-icon="list"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="filters.reporters"
                    :items="compUsersList"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.user.reporter')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.user.selectReporter')"
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="title"
                    prepend-icon="list"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="filters.assigned"
                    :items="compUsersList"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.user.assigned')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.user.selectAssigned')"
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="title"
                    prepend-icon="list"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                    ref="filterDatePicker"
                    v-model="filterDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filters.dates"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.dates"
                        :label="$vuetify.lang.t('$vuetify.dates.createdAt')"
                        prepend-icon="event"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      range
                      v-model="filters.dates"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="filterDatePicker = false"
                      >Cancel
                      </v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.filterDatePicker.save(filters.dates)"
                      >OK
                      </v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                    ref="filterReminderDatePicker"
                    v-model="filterReminderDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filters.reminder_dates"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.reminder_dates"
                        :label="$vuetify.lang.t('$vuetify.different.remind')"
                        prepend-icon="event"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      range
                      v-model="filters.reminder_dates"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="filterReminderDatePicker = false"
                      >
                        {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.filterReminderDatePicker.save(
                            filters.reminder_dates
                          )
                        "
                      >
                        {{ $vuetify.lang.t('$vuetify.buttons.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{
                $vuetify.lang.t('$vuetify.ticket.many')
              }}
            </v-card-title>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn small rounded @click="init" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
              <v-btn small rounded color="success" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.create') }}
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeader"
                :items="items"
                show-expand
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
                :items-per-page="20"
                :footer-props="{
                'items-per-page-options': [10, 20, 50, 100]
                }"
              >
                <template v-slot:item.id="{ item }">
                  <a @click="viewTicket(item.id)">{{ item.view_id }}</a>
                </template>
                <template v-slot:item.category_id="{ item }">
                  {{ $vuetify.lang.t('$vuetify.category.' + item.category_id) }}
                </template>
                <template v-slot:item.assigned="{ item }">
                  <v-chip-group column>
                    <v-chip v-for="(i) in item.assigned" :key="'assigned_'+ i.id" small outlined color="deep-purple accent-4">
                      {{ i.name }} {{ i.surname }}
                    </v-chip>
                  </v-chip-group>
                </template>
                <template v-slot:item.status_id="{ item }">
                  <v-chip small dark :color="item.status.color">
                    {{ $vuetify.lang.t('$vuetify.status.' + item.status_id) }}
                  </v-chip>
                </template>
                <template v-slot:item.reminder_date="{ item }">
                  <v-chip v-if="item.reminder_alert" small dark color="red">
                    {{ item.reminder_date }}
                  </v-chip>
                  <v-chip
                    v-else-if="item.reminder_date"
                    small
                    color="grey lighten-3"
                  >
                    {{ item.reminder_date }}
                  </v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="viewTicket(item.id)"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.ticket.view') }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">{{ item.description }}</td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
              $vuetify.lang.t('$vuetify.ticket.create')
            }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="ticketForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="ticket.project_id"
                    :items="compProjects"
                    :label="$vuetify.lang.t('$vuetify.different.project') + '*'"
                    :rules="notEmpty"
                    required
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="ticket.category_id"
                    :items="compTicketCategories"
                    :label="$vuetify.lang.t('$vuetify.category.category') + '*'"
                    :rules="notEmpty"
                    required
                    item-value="id"
                    item-text="title"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="ticket.assigned"
                    :items="compUsersList"
                    :label="$vuetify.lang.t('$vuetify.user.assignTo') + '*'"
                    multiple
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="title"
                    :rules="notEmpty"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="ticket.order_number"
                    type="number"
                    step="1"
                    clearable
                    :label="$vuetify.lang.t('$vuetify.order.number')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    v-model="reminderDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="250px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ticket.reminder_date"
                        :label="$vuetify.lang.t('$vuetify.different.remind')"
                        prepend-icon="event"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="nowDate"
                      first-day-of-week="1"
                      v-model="ticket.reminder_date"
                      @input="reminderDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ticket.summary"
                    :label="$vuetify.lang.t('$vuetify.different.summary') + '*'"
                    :hint="$vuetify.lang.t('$vuetify.ticket.summary')"
                    :rules="max255"
                    required
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="ticket.description"
                    outlined
                    :label="
                      $vuetify.lang.t('$vuetify.different.description') + '*'
                    "
                    :rules="notEmpty"
                    required
                    counter
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    counter
                    show-size
                    small-chips
                    multiple
                    :label="$vuetify.lang.t('$vuetify.different.files')"
                    ref="ticketFiles"
                    v-model="ticketFiles"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            :disabled="ticketLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="create"
            :disabled="!valid"
            :loading="ticketLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      ticketLoading: false,
      tableLoading: false,
      search: null,
      awaitingSearch: false,
      filterDatePicker: false,
      filterReminderDatePicker: false,
      reminderDatePicker: false,
      ticketFiles: null,
      ticket: {
        project_id: 2,
        category_id: null,
        assigned: null,
        status_id: null,
        order_number: null,
        summary: null,
        description: null,
        reminder_date: null
      },
      emptyTicket: {
        project_id: 2,
        category_id: null,
        assigned: null,
        status_id: null,
        order_number: null,
        summary: null,
        description: null,
        reminder_date: null
      },
      notEmpty: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      ],
      max255: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) =>
          (v && v.length <= 255) ||
          this.$vuetify.lang.t('$vuetify.validation.max255')
      ],
      totalItems: 0,
      options: {},
      items: [],
      filters: {
        projects: localStorage.getItem('ticket_projects')
          ? JSON.parse(localStorage.getItem('ticket_projects'))
          : null,
        categories: localStorage.getItem('ticket_categories')
          ? JSON.parse(localStorage.getItem('ticket_categories'))
          : null,
        statuses: localStorage.getItem('ticket_statuses')
          ? JSON.parse(localStorage.getItem('ticket_statuses'))
          : null,
        reporters: localStorage.getItem('ticket_reporters')
          ? JSON.parse(localStorage.getItem('ticket_reporters'))
          : null,
        assigned: localStorage.getItem('ticket_assigned')
          ? JSON.parse(localStorage.getItem('ticket_assigned'))
          : null,
        dates: localStorage.getItem('ticket_dates')
          ? JSON.parse(localStorage.getItem('ticket_dates'))
          : null,
        reminder_dates: localStorage.getItem('ticket_reminder_dates')
          ? JSON.parse(localStorage.getItem('ticket_reminder_dates'))
          : null
      },
      nowDate: new Date().toISOString().slice(0, 10)
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.ticket = Object.assign({}, this.emptyTicket)
        this.$refs.ticketForm.resetValidation()
        this.ticketFiles = null
      }
    },
    search(val) {
      if (!val || (val && val.length >= 3)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.init()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    options: {
      handler() {
        this.init()
      },
      deep: true
    },
    filters: {
      handler() {
        this.init()
      },
      deep: true
    },
    'filters.projects': function (val) {
      localStorage.setItem('ticket_projects', JSON.stringify(val))
    },
    'filters.categories': function (val) {
      localStorage.setItem('ticket_categories', JSON.stringify(val))
    },
    'filters.statuses': function (val) {
      localStorage.setItem('ticket_statuses', JSON.stringify(val))
    },
    'filters.reporters': function (val) {
      localStorage.setItem('ticket_reporters', JSON.stringify(val))
    },
    'filters.assigned': function (val) {
      localStorage.setItem('ticket_assigned', JSON.stringify(val))
    },
    'filters.dates': function (val) {
      localStorage.setItem('ticket_dates', JSON.stringify(val))
    },
    'filters.reminder_dates': function (val) {
      localStorage.setItem('ticket_reminder_dates', JSON.stringify(val))
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.ticket.many')
    }
  },
  computed: {
    compTicketCategories() {
      let arr = this.$store.getters.getTicketCategories
      let modified = []
      let t = this
      if (arr) {
        arr.forEach(function (entry) {
          modified.push({
            id: entry.id,
            title: t.$vuetify.lang.t('$vuetify.category.' + entry.id)
          })
        })
      }
      return modified
    },
    compTicketStatuses() {
      let arr = this.$store.getters.getTicketStatuses
      let modified = []
      let t = this
      if (arr) {
        arr.forEach(function (entry) {
          modified.push({
            id: entry.id,
            title: t.$vuetify.lang.t('$vuetify.status.' + entry.id)
          })
        })
      }
      return modified
    },
    compUsersList() {
      return this.$store.getters.getUsersList
    },
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    compHeader() {
      return [
        {text: 'ID', value: 'id'},
        {
          text: this.$vuetify.lang.t('$vuetify.different.project'),
          value: 'project.name',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.category.category'),
          value: 'category_id',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.status.status'),
          value: 'status_id',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.user.reporter'),
          value: 'reporter',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.user.assigned'),
          value: 'assigned',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.number'),
          value: 'order_number'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.summary'),
          value: 'summary',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.remind'),
          value: 'reminder_date'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        },
        {text: '', value: 'data-table-expand'}
      ]
    },
    compProjects() {
      return this.$store.getters.getProjects
    }
  },
  mounted() {
  },
  methods: {
    handleClick(row) {
      this.formModel = Object.assign(this.formModel, row)
      this.dialog = true
    },
    viewTicket(id) {
      this.$router.push({name: 'Ticket', params: {id: id}})
    },
    create() {
      if (this.$refs.ticketForm.validate()) {
        this.ticketLoading = true

        let formData = new FormData()
        //add files
        if (this.ticketFiles) {
          for (let i = 0; i < this.ticketFiles.length; i++) {
            let file = this.ticketFiles[i]
            formData.append('files[' + i + ']', file)
          }
        }

        // ticket
        for (let key in this.ticket) {
          if (this.ticket[key]) {
            if (typeof this.ticket[key] == 'object') {
              for (let item in this.ticket[key]) {
                formData.append(key + '[]', this.ticket[key][item])
              }
            } else formData.append(key, this.ticket[key])
          }
        }

        let headers = this.compAuthHeader
        headers.headers['Content-Type'] = 'multipart/form-data'

        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/ticket', formData, headers)
          .then((response) => {
            this.ticketLoading = false
            if (response.data.success) {
              this.init()
              this.dialog = false
            }
          })
          .catch((error) => {
            console.log(error)
            this.ticketLoading = false
          })
      }
    },
    init() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        descending: this.options.sortDesc[0],
        filters: this.filters
      }

      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/tickets', data, headers)
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }

          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    }
  }
}
</script>
